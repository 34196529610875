import { IconButton, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';

export function ButtonMenu({ title, items, onClick }: any) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>{title}</IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {items.map((m: any) => (
          <MenuItem
            key={m.title}
            onClick={() => {
              setAnchorEl(null);
              onClick(m);
            }}
          >
            {m.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
