import { billsSummaryActions } from '..';
import { DISPATCH, executeAction } from '../actions';

import { UserBillServices } from '../../shared/services/UserBillServices';
import { GetBillsSummaryByFilterDTO } from '../../shared/services/models/BillsModel';

export const loadSummary = (filter: GetBillsSummaryByFilterDTO) => {
  return async (dispatch: DISPATCH) => {
    await executeAction(dispatch, async () => {
      dispatch(billsSummaryActions.setItems([]));
      const result = await UserBillServices.getBillsSummaryByFilterAsync(
        filter
      );
      dispatch(billsSummaryActions.setItems(result.data || []));
    });
  };
};
