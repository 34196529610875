import { ApiServices } from './ApiServices';
import {
  SaveUserDTO,
  ProfileUserDTO,
  ProfileDocumentDTO,
} from './models/UserModel';

export class ProfileServices {
  static getMyProfile() {
    return ApiServices.get<ProfileUserDTO>(
      `${process.env.REACT_APP_ACC_API}/api/profile`
    );
  }

  static saveMyProfileAsync(request: SaveUserDTO) {
    return ApiServices.post<SaveUserDTO>(
      `${process.env.REACT_APP_ACC_API}/api/profile`,
      { request }
    );
  }

  static getMyProfileDocumentAsync() {
    return ApiServices.get<Array<ProfileDocumentDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/profiledocument`
    );
  }

  static saveMyProfileDocumentAsync(model: FormData) {
    return ApiServices.postForm<number>(
      `${process.env.REACT_APP_ACC_API}/api/profiledocument`,
      model
    );
  }

  static getMyProfileDocumentUrlAsync(docId: string) {
    return ApiServices.get<string>(
      `${process.env.REACT_APP_ACC_API}/api/profiledocument/${docId}`
    );
  }
}
