import { Box } from '@mui/material';
import { Animated404 } from '../../components/animated';

export function PageNotFound() {
  return (
    <Box
      display='flex'
      flexDirection={'column'}
      alignItems='center'
      justifyContent={'center'}
    >
      <Animated404 />
    </Box>
  );
}
