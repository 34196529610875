export const forceRefreshState = () => ({ refreshCount: 0 });
export const forceRefreshMethods = () => ({
  doForceRefresh(state: any, action: {}) {
    const n = new Date().getTime();
    state.refreshCount = state.refreshCount === n ? n + 1 : n;
  },
});

export function pageInitialState<T>() {
  return {
    pageNumber: 0,
    pageSize: 10,
    totalCount: 0,
    sortDirection: 0,
    sortColumn: '',
    searchText: '',
    items: [] as Array<T>,
    ...forceRefreshState(),
  };
}

export const pageReducerMethods = {
  setLastFilter(state: any, action: { payload: any }) {
    state.lastFilter = action.payload;
  },
  setItems(state: any, action: { payload: any }) {
    state.items = action.payload;
  },
  setSearchText(state: any, action: { payload: any }) {
    state.searchText = action.payload;
  },
  setPageSize(state: any, action: { payload: any }) {
    state.pageSize = action.payload;
  },
  setPageNumber(state: any, action: { payload: any }) {
    state.pageNumber = action.payload;
  },
  setTotalCount(state: any, action: { payload: any }) {
    state.totalCount = action.payload;
  },
  setSort(state: any, action: { payload: any }) {
    const { sortColumn, sortDirection } = action.payload;
    state.sortColumn = sortColumn;
    state.sortDirection =
      sortDirection >= 0 ? sortDirection : 1 - state.sortDirection;
  },
  ...forceRefreshMethods(),
};
