import { Box, Button, Divider, Grid, Typography } from '@mui/material';

import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { authActions } from '../../store';
import { OtpInput } from '../../components/otp';

export function TwoFacForm() {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.auth.loading);
  const [code2Fac, setCode2Fac] = useState('');

  const onSubmit = useCallback(
    (e: any) => {
      dispatch(authActions.login2Fac(code2Fac));
      e.preventDefault();
    },
    [code2Fac, dispatch]
  );

  return (
    <>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        rowGap={1}
        p={1}
        textAlign={'center'}
      >
        <Typography variant={'h6'} color={'primary'}>
          Hi, Welcome back
        </Typography>
        <Typography variant={'body2'} color={'grey.400'}>
          Code is sent to you using your prefered method.
        </Typography>
        <Typography variant={'body1'} color={'grey.500'}>
          Please enter the code
        </Typography>
      </Box>

      <form noValidate onSubmit={onSubmit}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: 1,
          }}
        >
          <Grid container spacing={3} sx={{ maxWidth: ['inherit', 400] }}>
            <Grid item xs={12}>
              <OtpInput numberOfDigits={5} onComplete={setCode2Fac} />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gridGap: (theme) => theme.spacing(1),
                }}
              >
                <Button
                  disabled={loading || code2Fac.length < 5}
                  variant='contained'
                  fullWidth
                  color='primary'
                  type='submit'
                >
                  Continue
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  );
}
