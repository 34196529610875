import { ApiServices } from './ApiServices';
import {
  ContractDTO,
  ContractLineItemDTO,
  ContractTaskItemDTO,
  CreateContractDTO,
  CreateContractLineItemDTO,
  SaveContractTaskDTO,
  TerminateDTO,
} from './models/ContractModel';

export class ContractServices {
  static createContract(request: CreateContractDTO) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/contractsupdate`,
      {
        request,
      }
    );
  }

  static terminateContract(contractId: number, request: TerminateDTO) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/contractsupdate/${contractId}/terminate`,
      {
        request,
      }
    );
  }

  static getContractById(contractId: number) {
    return ApiServices.get<ContractDTO>(
      `${process.env.REACT_APP_ACC_API}/api/contracts/${contractId}`
    );
  }

  static getContractItems(contractId: number) {
    return ApiServices.get<Array<ContractLineItemDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/contracts/${contractId}/items`
    );
  }

  static createContractItem(
    contractId: number,
    request: CreateContractLineItemDTO
  ) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/contractsupdate/${contractId}/items`,
      {
        request,
      }
    );
  }

  static terminateContractItem(
    contractId: number,
    contractItemId: number,
    request: TerminateDTO
  ) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/contractsupdate/${contractId}/items/${contractItemId}/terminate`,
      { request }
    );
  }

  static getContractTasks(contractId: number) {
    return ApiServices.get<Array<ContractTaskItemDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/contracts/${contractId}/tasks`
    );
  }

  static createContractTask(contractId: number, request: SaveContractTaskDTO) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/contractsupdate/${contractId}/tasks`,
      {
        request,
      }
    );
  }

  static updateContractTask(
    contractId: number,
    taskId: number,
    request: SaveContractTaskDTO
  ) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/contractsupdate/${contractId}/tasks/${taskId}`,
      {
        request,
      }
    );
  }

  static updateContractTitle(contractId: number, title: string) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/contractsupdate/${contractId}/title`,
      {
        title,
      }
    );
  }

  static updateContactName(contractId: number, title: string) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/contractsupdate/${contractId}/contactname`,
      {
        title,
      }
    );
  }

  static updateContactEmail(contractId: number, title: string) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/contractsupdate/${contractId}/contactemail`,
      {
        title,
      }
    );
  }
}
