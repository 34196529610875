import { ApiServices } from './ApiServices';
import {
  VendorBillDTO,
  GetVendorBillsByFilterDTO,
  GetVendorsByFilterDTO,
  UpdateVendorBillDTO,
  VendorDTO,
} from './models/VendorBillsModel';
import { PaginationResultDTO } from './models/PaginationModel';

export class VendorBillServices {
  static getVendorsByFilterAsync(request: GetVendorsByFilterDTO) {
    return ApiServices.post<PaginationResultDTO<VendorDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/vendors/search`,
      {
        request,
      }
    );
  }

  static getVendorBillsByFilterAsync(request: GetVendorBillsByFilterDTO) {
    return ApiServices.post<PaginationResultDTO<VendorBillDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/vendorbill/search`,
      {
        request,
      }
    );
  }

  static createBillAsync(vendorId: number, model: FormData) {
    return ApiServices.postForm<number>(
      `${process.env.REACT_APP_ACC_API}/api/vendorbill/${vendorId}/create`,
      model
    );
  }

  static updateBillAsync(
    vendorId: number,
    billId: number,
    model: UpdateVendorBillDTO
  ) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/vendorbill/${vendorId}/${billId}/update`,
      model
    );
  }

  static rejectBillAsync(vendorId: number, billId: number) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/vendorbill/${vendorId}/${billId}/reject`
    );
  }

  static getDocumentUrlAsync(vendorId: number, docId: string) {
    return ApiServices.get<string>(
      `${process.env.REACT_APP_ACC_API}/api/vendorbill/${vendorId}/${docId}`
    );
  }
}
