import { createSlice } from '@reduxjs/toolkit';
import {
  loadUser,
  saveUser,
  searchTimesheets,
  terminateUser,
  initializeRoles,
  assignUserManager,
  downloadFile,
  approveDoc,
  rejectDoc,
} from './user-actions';
import { ContextActions } from '..';
import {
  ProfileDocumentDTO,
  RoleDTO,
  UserDTO,
} from '../../shared/services/models/UserModel';
import { dateToString, firstOfMonth, lastOfMonth } from '../../shared/DateUtil';
import { UserTaskItemDTO } from '../../shared/services/models/UserTaskModel';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null as UserDTO | null,
    roles: [] as Array<RoleDTO>,
    documents: [] as Array<ProfileDocumentDTO>,
    timesheets: [] as Array<UserTaskItemDTO>,
    docUrl: '',
    showDoc: false,

    startDate: dateToString(firstOfMonth()),
    endDate: dateToString(lastOfMonth()),
  },
  reducers: {
    reset(state) {
      Object.assign(state, userSlice.getInitialState());
    },
    setRoles(state, action) {
      state.roles = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setDocuments(state, action) {
      state.documents = action.payload ?? [];
    },
    setTimesheets(state, action) {
      state.timesheets = action.payload ?? [];
    },
    setDocUrl(state, action) {
      state.docUrl = action.payload;
      state.showDoc = !!state.docUrl;
    },
    setShowDoc(state, action) {
      state.showDoc = action.payload;
    },
    setDateRange(state, action) {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
  },
});

export const userActions = {
  ...userSlice.actions,
  loadUser,
  initializeRoles,
  searchTimesheets,
  saveUser,
  terminateUser,
  assignUserManager,
  downloadFile,
  approveDoc,
  rejectDoc,
};

export type UserContextType = ReturnType<typeof userSlice.getInitialState> &
  Partial<ContextActions<typeof userActions>>;

export const UserContextValue = userSlice.getInitialState();
