import { DISPATCH, executeAction, GET_STATE } from '../actions';
import { UserNotificationServices } from '../../shared/services/UserNotificationServices';
import { notificationsActions } from './notifications-slice';
import { uiActions } from '../ui/ui-slice';

export const loadAll = ({ refreshCount }: { refreshCount: any }) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        notifications: { items, refreshCount: lastRefreshCount },
      } = getState();
      if (!items || items.length === 0 || lastRefreshCount !== refreshCount) {
        const result = await UserNotificationServices.getItemsAsync();
        dispatch(notificationsActions.setItems(result.data));
      }
    });
  };
};

export const deleteItem = (notificationId: string) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        notifications: { items },
      } = getState();

      await UserNotificationServices.deleteAsync(notificationId);

      dispatch(
        notificationsActions.setItems(
          items.filter((x) => x.notificationId !== notificationId)
        )
      );

      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'The notification item has been deleted successfully.',
        })
      );
    });
  };
};

export const markRead = (notificationId: string) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        notifications: { items },
      } = getState();

      await UserNotificationServices.markReadAsync(notificationId);
      dispatch(
        notificationsActions.setItems(
          items.map((x) =>
            x.notificationId === notificationId ? { ...x, read: true } : x
          )
        )
      );
    });
  };
};
