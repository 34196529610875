import { createSlice } from '@reduxjs/toolkit';
import {
  initialize,
  saveProfile,
  saveAvatarPic,
  saveProfilePic,
  saveDocument,
  downloadFile,
} from './profile-actions';
import { ContextActions } from '..';
import { forceRefreshMethods, forceRefreshState } from '../pagination';
import {
  ProfileDocumentDTO,
  ProfileUserDTO,
} from '../../shared/services/models/UserModel';

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    ...forceRefreshState(),
    profile: null as ProfileUserDTO | null,
    showAvatarPicEdit: false,
    showProfilePicEdit: false,
    selectedDoc: null as ProfileDocumentDTO | null,
    documents: [] as Array<ProfileDocumentDTO>,
    docUrl: '',
    showDoc: false,
  },
  reducers: {
    ...forceRefreshMethods(),
    reset(state) {
      Object.assign(state, profileSlice.getInitialState());
    },
    setProfile(state, action) {
      state.profile = action.payload;
    },
    setProfileDocuments(state, action) {
      state.documents = action.payload ?? [];
    },
    setShowAvatarPicEdit(state, action) {
      state.showAvatarPicEdit = action.payload;
    },
    setShowProfilePicEdit(state, action) {
      state.showProfilePicEdit = action.payload;
    },
    setSelectedDoc(state, action) {
      state.selectedDoc = action.payload;
    },
    setDocUrl(state, action) {
      state.docUrl = action.payload;
      state.showDoc = !!state.docUrl;
    },
    setShowDoc(state, action) {
      state.showDoc = action.payload;
    },
  },
});

export const profileActions = {
  ...profileSlice.actions,
  initialize,
  saveProfile,
  saveAvatarPic,
  saveProfilePic,
  saveDocument,
  downloadFile,
};

export type ProfileContextType = ReturnType<
  typeof profileSlice.getInitialState
> &
  Partial<ContextActions<typeof profileActions>>;

export const ProfileContextValue = profileSlice.getInitialState();
