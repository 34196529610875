import { createSlice } from '@reduxjs/toolkit';

import {
  searchBills,
  createBill,
  approveBill,
  downloadFile,
  rejectBill,
  loadVendors,
} from './vendor-bills-actions';
import { pageInitialState, pageReducerMethods } from '../pagination';
import { ContextActions } from '..';
import {
  VendorBillDTO,
  VendorBillStatusEnum,
  VendorDTO,
} from '../../shared/services/models/VendorBillsModel';

export const vendorBillsSlice = createSlice({
  name: 'vendorBills',
  initialState: {
    ...pageInitialState<VendorBillDTO>(),
    lastFilter: null,

    sortColumn: 'submittedDate',
    sortDirection: 1,

    statusId: VendorBillStatusEnum.Submitted,

    showCreateBill: false,
    selectedBill: null as VendorBillDTO | null,
    docUrl: '',
    showDoc: false,
    vendors: [] as VendorDTO[],
  },
  reducers: {
    ...pageReducerMethods,
    reset(state) {
      Object.assign(state, vendorBillsSlice.getInitialState());
    },

    setFilterStatus(state, action) {
      state.statusId = action.payload;
    },
    setShowCreateBill(state, action) {
      state.showCreateBill = action.payload;
    },
    setSelectedBill(state, action) {
      state.selectedBill = action.payload;
    },
    setDocUrl(state, action) {
      state.docUrl = action.payload;
      state.showDoc = !!state.docUrl;
    },
    setShowDoc(state, action) {
      state.showDoc = action.payload;
    },
    setVendors(state, action) {
      state.vendors = action.payload;
    },
  },
});

export const vendorBillsActions = {
  ...vendorBillsSlice.actions,
  searchBills,
  createBill,
  approveBill,
  downloadFile,
  rejectBill,
  loadVendors,
};

export type VendorBillsContextType = ReturnType<
  typeof vendorBillsSlice.getInitialState
> &
  Partial<ContextActions<typeof vendorBillsActions>>;

export const VendorBillsContextValue = vendorBillsSlice.getInitialState();
