import { createSlice } from '@reduxjs/toolkit';
import { loadAll, deleteItem, markRead } from './notifications-actions';
import { ContextActions } from '..';

import { forceRefreshMethods, forceRefreshState } from '../pagination';
import { UserNotificationItemDTO } from '../../shared/services/models/UserNotificationModel';

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    items: [] as Array<UserNotificationItemDTO>,
    ...forceRefreshState(),
  },
  reducers: {
    ...forceRefreshMethods(),
    reset(state) {
      Object.assign(state, notificationsSlice.getInitialState());
    },
    setItems(state, action) {
      state.items = action.payload;
    },
  },
});

export const notificationsActions = {
  ...notificationsSlice.actions,
  loadAll,
  deleteItem,
  markRead,
};

export type NotificationsContextType = ReturnType<
  typeof notificationsSlice.getInitialState
> &
  Partial<ContextActions<typeof notificationsActions>>;

export const NotificationsContextValue = notificationsSlice.getInitialState();
