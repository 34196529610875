import { createSlice } from '@reduxjs/toolkit';
import { search } from './roles-permissions-actions';
import { ContextActions } from '..';
import { RoleWithPermissionDTO } from '../../shared/services/models/OrganizationModel';

export const rolesAndPermissionsSlice = createSlice({
  name: 'rolesAndPermissions',
  initialState: {
    items: [] as Array<RoleWithPermissionDTO>,
    selectedRole: null as any | null,
  },
  reducers: {
    reset(state) {
      Object.assign(state, rolesAndPermissionsSlice.getInitialState());
    },
    selectRole(state, action) {
      state.selectedRole = action.payload;
    },
    setItems(state, action) {
      state.items = action.payload;
    },
  },
});

export const rolesAndPermissionsActions = {
  ...rolesAndPermissionsSlice.actions,
  search,
};

export type RolesAndPermissionsContextType = ReturnType<
  typeof rolesAndPermissionsSlice.getInitialState
> &
  Partial<ContextActions<typeof rolesAndPermissionsActions>>;

export const RolesAndPermissionsContextValue =
  rolesAndPermissionsSlice.getInitialState();
