import { billsActions, uiActions } from '..';
import { DISPATCH, executeAction, GET_STATE } from '../actions';
import { shallowEqual } from 'react-redux';

import { UserBillServices } from '../../shared/services/UserBillServices';
import {
  BillDTO,
  GetBillsByFilterDTO,
  UpdateBillDTO,
} from '../../shared/services/models/BillsModel';

export const searchBills = (filter: GetBillsByFilterDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        bills: { items, lastFilter },
      } = getState();
      if (
        !items ||
        items.length === 0 ||
        !lastFilter ||
        !shallowEqual(lastFilter, filter)
      ) {
        dispatch(billsActions.setItems([]));
        dispatch(billsActions.setLastFilter(filter));
        const result = await UserBillServices.getMyBillsByFilterAsync(filter);
        dispatch(billsActions.setItems(result.data.items || []));
        dispatch(billsActions.setTotalCount(result.data.totalCount));
      }
    });
  };
};

export const createBill = (model: FormData) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await UserBillServices.createBillAsync(model);
      dispatch(billsActions.doForceRefresh());
      dispatch(billsActions.setSelectedBill(null));
      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Invoice/Bill was successfully created',
        })
      );
    });
  };
};

export const approveBill = (billId: number, model: UpdateBillDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await UserBillServices.updateBillAsync(billId, model);
      dispatch(billsActions.doForceRefresh());
      dispatch(billsActions.setSelectedBill(null));
      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Invoice/Bill was successfully approved',
        })
      );
    });
  };
};

export const downloadFile = (model: BillDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const path = await UserBillServices.getDocumentUrlAsync(
        model.userId,
        model.docId
      );
      dispatch(billsActions.setDocUrl(path.data));
    });
  };
};

export const rejectBill = (billId: number) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await UserBillServices.rejectBillAsync(billId);
      dispatch(billsActions.doForceRefresh());
      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Invoice/Bill was successfully rejected',
        })
      );
    });
  };
};
