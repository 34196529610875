import { ApiServices } from './ApiServices';
import {
  ChangePasswordDTO,
  TokenResultDTO,
  ResetPasswordDTO,
} from './models/AuthModel';

export class LoginServices {
  static login(userName: string, password: string) {
    return ApiServices.post<TokenResultDTO>(
      `${process.env.REACT_APP_ACC_API}/api/auth/token`,
      {
        Username: userName,
        password,
      }
    );
  }

  static loginGoogle(credential: string) {
    return ApiServices.post<TokenResultDTO>(
      `${process.env.REACT_APP_ACC_API}/api/auth/google`,
      {
        credential,
      }
    );
  }

  static login2Fac(userName: string, token2Fac: string, code2Fac: string) {
    return ApiServices.post<TokenResultDTO>(
      `${process.env.REACT_APP_ACC_API}/api/auth/2fac`,
      {
        Username: userName,
        token2Fac,
        code2Fac,
      }
    );
  }

  static switchAsync(organizationId: number) {
    return ApiServices.post<TokenResultDTO>(
      `${process.env.REACT_APP_ACC_API}/api/auth/switch/${organizationId}`
    );
  }

  static changePasswordAsync(model: ChangePasswordDTO) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/auth/change`,
      model
    );
  }

  static forgotPasswordAsync(userName: string) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/auth/forgot`,
      { userName }
    );
  }

  static resetPasswordAsync(model: ResetPasswordDTO) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/auth/reset`,
      model
    );
  }
}
