import {
  Paper,
  Modal,
  List,
  ListItemButton,
  styled,
  Typography,
} from '@mui/material';
import { useOrgs } from '../../contexts/OrgContext';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  padding: theme.spacing(1),
  '& .MuiTypography-root': {
    padding: theme.spacing(2),
  },
}));

export function OrganizationSelector() {
  const { open, organizations, selectedOrganization, selectOrganization } =
    useOrgs();

  return (
    <Modal open={open}>
      <StyledPaper>
        <Typography variant={'h6'}>Clients</Typography>
        <List>
          {organizations.map((o, i) => (
            <ListItemButton
              key={i}
              selected={
                o.organizationId === selectedOrganization?.organizationId
              }
              onClick={() => selectOrganization!(o)}
            >
              {o.name}
            </ListItemButton>
          ))}
        </List>
      </StyledPaper>
    </Modal>
  );
}
