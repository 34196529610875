import { ApiServices } from './ApiServices';
import {
  DashboardDocument,
  DashboardFilterDTO,
  DashboardPayment,
  DashboardTimeOff,
  DashboardTimeOffSummary,
  DashboardTimesheet,
  ResourceItem,
  RevenueItem,
  TimeSpentItem,
} from './models/DashboardModel';

export class DashboardServices {
  static getSpentItemsAsync(request: DashboardFilterDTO) {
    return ApiServices.post<Array<TimeSpentItem>>(
      `${process.env.REACT_APP_ACC_API}/api/dashboard/timespent`,
      {
        request,
      }
    );
  }

  static getRevenueItemsAsync(request: DashboardFilterDTO) {
    return ApiServices.post<Array<RevenueItem>>(
      `${process.env.REACT_APP_ACC_API}/api/dashboard/revenue`,
      {
        request,
      }
    );
  }

  static getResourcesAsync(request: DashboardFilterDTO) {
    return ApiServices.post<Array<ResourceItem>>(
      `${process.env.REACT_APP_ACC_API}/api/dashboard/resource`,
      {
        request,
      }
    );
  }

  static getTimesheetItemsAsync(request: DashboardFilterDTO) {
    return ApiServices.post<Array<DashboardTimesheet>>(
      `${process.env.REACT_APP_ACC_API}/api/dashboard/timesheets`,
      {
        request,
      }
    );
  }

  static getTimeOffsAsync() {
    return ApiServices.post<Array<DashboardTimeOff>>(
      `${process.env.REACT_APP_ACC_API}/api/dashboard/timeoffs`
    );
  }

  static getTimeOffSummariesAsync(request: DashboardFilterDTO) {
    return ApiServices.post<Array<DashboardTimeOffSummary>>(
      `${process.env.REACT_APP_ACC_API}/api/dashboard/timeoffs/summary`,
      { request }
    );
  }

  static getPaymentsAsync() {
    return ApiServices.post<Array<DashboardPayment>>(
      `${process.env.REACT_APP_ACC_API}/api/dashboard/payments`
    );
  }

  static getDocumentsAsync() {
    return ApiServices.post<Array<DashboardDocument>>(
      `${process.env.REACT_APP_ACC_API}/api/dashboard/documents`
    );
  }
}
