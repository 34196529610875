import { ApiServices } from './ApiServices';
import { UserNotificationItemDTO } from './models/UserNotificationModel';

export class UserNotificationServices {
  static getItemsAsync() {
    return ApiServices.get<Array<UserNotificationItemDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/usernotifications/index`
    );
  }

  static deleteAsync(notificationId: string) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/usernotifications/${notificationId}/delete`
    );
  }

  static markReadAsync(notificationId: string) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/usernotifications/${notificationId}/read`
    );
  }
}
