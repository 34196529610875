import { shallowEqual } from 'react-redux';
import { useAppSelector } from '.';

export function useOrganization() {
  const { organizations, selectedOrganization } = useAppSelector(
    ({ orgs: { organizations, selectedOrganization } }) => ({
      organizations,
      selectedOrganization,
    }),
    shallowEqual
  );

  return { organizations, selectedOrganization };
}
