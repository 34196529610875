import { ApiServices } from './ApiServices';
import {
  CreateInvoiceDTO,
  GetInvoicesByFilterDTO,
  InvoiceInfo,
  InvoicePickItemDTO,
  InvoicePreviewItemDTO,
  InvoiceSummaryDTO,
  NeedsApprovalItemDTO,
} from './models/InvoiceModel';
import { PaginationResultDTO } from './models/PaginationModel';

export class InvoiceServices {
  static getInvoicesSummaryByFilter(request: GetInvoicesByFilterDTO) {
    return ApiServices.post<InvoiceSummaryDTO>(
      `${process.env.REACT_APP_ACC_API}/api/invoices/summary`,
      {
        request,
      }
    );
  }

  static getInvoicesByFilter(request: GetInvoicesByFilterDTO) {
    return ApiServices.post<PaginationResultDTO<InvoicePickItemDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/invoices/search`,
      {
        request,
      }
    );
  }

  static getInvoiceById(invoiceId: number) {
    return ApiServices.get<Array<InvoiceInfo>>(
      `${process.env.REACT_APP_ACC_API}/api/invoices/${invoiceId}`
    );
  }

  static createInvoice(request: CreateInvoiceDTO) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/invoices/${request.clientId}`,
      request
    );
  }

  static completeInvoice(invoiceId: number, paidDate: string) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/invoices/${invoiceId}/complete`,
      {
        paidDate,
      }
    );
  }

  static previewInvoice(request: CreateInvoiceDTO) {
    return ApiServices.post<Array<InvoicePreviewItemDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/invoices/${request.clientId}/preview`,
      request
    );
  }

  static pendingApproval(request: CreateInvoiceDTO) {
    return ApiServices.post<Array<NeedsApprovalItemDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/invoices/${request.clientId}/approval`,
      request
    );
  }
}
