import { createSlice } from '@reduxjs/toolkit';
import {
  search,
  saveOrganization,
  saveAvatarPic,
} from './organizations-actions';
import { ContextActions } from '..';
import { OrganizationDTO } from '../../shared/services/models/OrganizationModel';
import { pageInitialState, pageReducerMethods } from '../pagination';

export const organizationsSlice = createSlice({
  name: 'organizations',
  initialState: {
    ...pageInitialState<OrganizationDTO>(),
    lastOrganizationFilter: null,
    selectedOrganization: null as OrganizationDTO | null,
    organizationId: 0,
  },
  reducers: {
    ...pageReducerMethods,
    reset(state) {
      Object.assign(state, organizationsSlice.getInitialState());
    },
    selectOrganization(state, action) {
      state.selectedOrganization = action.payload;
    },
    setLastOrganizationFilter(state, action) {
      state.lastOrganizationFilter = action.payload;
    },
    setShowAvatarPicEdit(state, action) {
      state.organizationId = action.payload;
    },
  },
});

export const organizationsActions = {
  ...organizationsSlice.actions,
  search,
  saveOrganization,
  saveAvatarPic,
};

export type OrganizationsContextType = ReturnType<
  typeof organizationsSlice.getInitialState
> &
  Partial<ContextActions<typeof organizationsActions>>;

export const OrganizationsContextValue = organizationsSlice.getInitialState();
