import { ApiServices } from './ApiServices';
import {
  BillDTO,
  BillSummaryDTO,
  GetBillsByFilterDTO,
  GetBillsSummaryByFilterDTO,
  UpdateBillDTO,
} from './models/BillsModel';
import { PaginationResultDTO } from './models/PaginationModel';

export class UserBillServices {
  static getMyBillsByFilterAsync(request: GetBillsByFilterDTO) {
    return ApiServices.post<PaginationResultDTO<BillDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/userbill/search`,
      {
        request,
      }
    );
  }

  static getBillsSummaryByFilterAsync(request: GetBillsSummaryByFilterDTO) {
    return ApiServices.post<BillSummaryDTO[]>(
      `${process.env.REACT_APP_ACC_API}/api/userbill/summary`,
      request
    );
  }

  static createBillAsync(model: FormData) {
    return ApiServices.postForm<number>(
      `${process.env.REACT_APP_ACC_API}/api/userbill/create`,
      model
    );
  }

  static updateBillAsync(billId: number, model: UpdateBillDTO) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/userbill/${billId}/update`,
      model
    );
  }

  static rejectBillAsync(billId: number) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/userbill/${billId}/reject`
    );
  }

  static getDocumentUrlAsync(userId: number, docId: string) {
    return ApiServices.get<string>(
      `${process.env.REACT_APP_ACC_API}/api/userbill/${userId}/${docId}`
    );
  }
}
