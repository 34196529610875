export const INITIAL_RESET = {
  keepDirty: false,
  keepTouched: false,
};

export const CANCEL_RESET = {
  keepDirty: false,
  keepTouched: false,
  keepErrors: false,
  keepIsValid: true,
  keepValues: true,
};

export const APP_NAME = 'Time Track';

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export function groupBy<T, K extends keyof any>(
  list: T[],
  getKey: (item: T) => K
) {
  return list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) previous[group] = [];
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);
}

export function min<T>(arr: Array<T>, defaultValue: T) {
  return arr && arr.length > 0
    ? arr.reduce((p, c) => (p && p < c ? p : c))
    : defaultValue;
}
export function max<T>(arr: Array<T>, defaultValue: T) {
  return arr && arr.length > 0
    ? arr.reduce((p, c) => (p && p > c ? p : c))
    : defaultValue;
}

export function ccyFormat(num: number, decimals?: number) {
  return num !== undefined ? `$${num.toFixed(decimals ?? 2)}` : '';
}

const defaultOptions = {
  significantDigits: 2,
  thousandsSeparator: ',',
  decimalSeparator: '.',
  symbol: '$',
};

type CCFormatOptions = Partial<typeof defaultOptions>;

export function formatCurrency(value: number, options: CCFormatOptions) {
  if (typeof value !== 'number') value = 0.0;
  options = { ...defaultOptions, ...options };
  const v = value.toFixed(options.significantDigits);

  const [currency, decimal] = v.split('.');
  return `${options.symbol} ${currency.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    options.thousandsSeparator ?? ','
  )}${decimal ? `${options.decimalSeparator}${decimal}` : ''}`;
}
