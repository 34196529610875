import { Box, Paper, Typography, styled } from '@mui/material';

import { useEffect } from 'react';
import { Copyright, Page } from '../../components/Layout';
import { useAppSelector } from '../../hooks';
import { APP_NAME } from '../../shared/HelperUtil';
import { LoginForm } from './LoginForm';
import { TwoFacForm } from './TwoFacForm';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  columnGap: theme.spacing(2),
  '& > .MuiBox-root': {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      img: {
        width: '100%',
        maxHeight: 'calc(100vh - 60px)',
      },
    },
  },
  '& > .MuiPaper-root': {
    zIndex: 111,
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
      minWidth: 400,
      maxWidth: 420,
    },
  },
}));

const images = ['dev1.jpeg', 'dev2.jpeg', 'dev3.jpeg'];
const index = parseInt((Math.random() * 100).toFixed(0)) % images.length;

export function LoginPage() {
  const user = useAppSelector((state) => state.auth.user);
  const token2Fac = useAppSelector((state) => state.auth.token2Fac);

  useEffect(() => {
    if (user) window.location.href = '/';
  }, [user]);

  return (
    <Box
      height={'100vh'}
      display={'flex'}
      flexDirection={'column'}
      sx={{
        '& > div': { flex: 1, '& > .MuiContainer-root': { height: '100%' } },
      }}
    >
      <Page maxWidth={'xl'}>
        <StyledBox>
          <Box flex={1} sx={{ overflow: 'clip', borderRadius: 2 }}>
            <img
              src={`https://kacoders.imgix.net/site/${images[index]}`}
              alt='logo'
            />
          </Box>
          <Paper elevation={6}>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              columnGap={1}
              p={1}
              marginBottom={4}
            >
              <img
                src={'/images/timetrack.png'}
                width={32}
                title={APP_NAME}
                alt={APP_NAME}
              />
              <Typography variant={'h5'}>{APP_NAME}</Typography>
            </Box>

            {token2Fac && token2Fac !== '' ? <TwoFacForm /> : <LoginForm />}
          </Paper>
        </StyledBox>
      </Page>
      <Copyright />
    </Box>
  );
}
