import { PaginationFilterDTO } from './PaginationModel';

export enum VendorBillStatusEnum {
  Submitted = 14002,
  Approved = 14003,
  Rejected = 14004,
}

export interface VendorDTO {
  name: string;
  vendorId: number;
}

export interface GetVendorsByFilterDTO extends PaginationFilterDTO {
  vendorId?: number;
  statusId?: number;
}

export interface GetVendorBillsByFilterDTO extends PaginationFilterDTO {
  startDate?: string;
  endDate?: string;
  statusId?: number;
  organizationId?: number;
}

export interface VendorBillDTO {
  billId: number;
  vendorId: number;
  docId: string;
  firstName: string;
  lastName: string;
  avatarUrl: string;
  startDate: string;
  endDate: string;
  billType: string;
  billStatusId: number;
  transactionReference?: string;
  currency: string;
  paidAmount?: number;
  feeAmount?: number;
  approvedUserId?: number;
  approvedDate?: string;
  submittedDate?: string;
}

export interface CreateVendorBillDTO {
  startDate: string;
  endDate: string;
}

export interface UpdateVendorBillDTO {
  statusId: number;
  currency: string;
  totalAmount: number;
  convertedCurrency: string;
  convertedAmount: number;
  transactionReference: string;
}
