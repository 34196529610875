import { DISPATCH, executeAction, GET_STATE } from '../actions';
import { LoginServices } from '../../shared/services/LoginServices';
import { authActions } from './auth-slice';
import { authToken } from '../../shared/services/ApiServices';
import { UserOrganizationDTO } from '../../shared/services/models/UserOrganizationModel';
import { uiActions } from '../ui/ui-slice';

function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

export const initialize = () => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const result = localStorage.getItem(authToken);
      const data = JSON.parse(result || '{}');
      if (data && data.accessToken) {
        dispatch(authActions.setLoginUser(parseJwt(data.accessToken)));
      }
    });
  };
};

export const login = (userName: string, password: string) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const result = await LoginServices.login(userName, password);
      if (result.data.token2Fac) {
        dispatch(
          authActions.setTwoFacToken({
            userName,
            token2Fac: result.data.token2Fac,
          })
        );
      } else {
        localStorage.setItem(authToken, JSON.stringify(result.data));
        localStorage.setItem('UserId', `${result.data.subject}`);
        dispatch(authActions.setLoginUser(parseJwt(result.data.accessToken)));
      }
    });
  };
};

export const login2Fac = (code2Fac: string) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        auth: { userName, token2Fac },
      } = getState();

      const result = await LoginServices.login2Fac(
        userName!,
        token2Fac!,
        code2Fac
      );
      localStorage.setItem(authToken, JSON.stringify(result.data));
      localStorage.setItem('UserId', `${result.data.subject}`);
      dispatch(authActions.setLoginUser(parseJwt(result.data.accessToken)));
    });
  };
};

export const loginGoogle = (id: string, retUrl: string) => {
  return async (dispatch: DISPATCH) => {
    await executeAction(dispatch, async () => {
      try {
        const result = await LoginServices.loginGoogle(id);
        localStorage.setItem(authToken, JSON.stringify(result.data));
        localStorage.setItem('UserId', `${result.data.subject}`);
        dispatch(authActions.setLoginUser(parseJwt(result.data.accessToken)));
        window.location.href = retUrl || '/';
      } catch (e) {
        dispatch(
          uiActions.setMessage({
            message: 'Invalid login',
            messageType: 'error',
          })
        );
      }
    });
  };
};

export const logout = () => {
  return (dispatch: DISPATCH, getState: GET_STATE) => {
    localStorage.clear();
    dispatch(authActions.setLoginUser(null));
  };
};

export const changePassword = (oldPassword: string, password: string) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await LoginServices.changePasswordAsync({
        oldPassword,
        password,
      });

      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Password change was successfully',
        })
      );
      setTimeout(() => (window.location.href = '/profile'), 2000);
    });
  };
};

export const forgotPassword = (userName: string) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await LoginServices.forgotPasswordAsync(userName);

      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message:
            'Reset password email will be sent if the user is in the system.',
        })
      );
      setTimeout(() => (window.location.href = '/login'), 2000);
    });
  };
};

export const resetPassword = (recoverToken: string, newPassword: string) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await LoginServices.resetPasswordAsync({
        recoverToken,
        newPassword,
      });

      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Password change was successfully',
        })
      );
      setTimeout(() => (window.location.href = '/profile'), 2000);
    });
  };
};

export const switchOrganization = (organization: UserOrganizationDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const result = await LoginServices.switchAsync(
        organization.organizationId
      );
      localStorage.setItem(authToken, JSON.stringify(result.data));
      dispatch(authActions.setLoginUser(parseJwt(result.data.accessToken)));
      localStorage.setItem('OrganizationId', `${organization.organizationId}`);
      localStorage.setItem('UserId', `${result.data.subject}`);
      window.location.href = '/';
    });
  };
};
