import { ApiServices } from './ApiServices';
import {
  GetOrganizationsByFilterDTO,
  OrganizationDTO,
  OrganizationPicDTO,
  RoleWithPermissionDTO,
  SaveOrganizationDTO,
} from './models/OrganizationModel';
import { PaginationResultDTO } from './models/PaginationModel';

export class AdminServices {
  static getOrganizationsAsync(request: GetOrganizationsByFilterDTO) {
    return ApiServices.post<PaginationResultDTO<OrganizationDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/admin/organizations/search`,
      { request }
    );
  }

  static getRolesAsync() {
    return ApiServices.post<Array<RoleWithPermissionDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/admin/roles`
    );
  }

  static updateOrganizationAsync(
    organizationId: number,
    request: SaveOrganizationDTO
  ) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/admin/organizations/${organizationId}`,
      {
        request,
      }
    );
  }

  static createOrganizationAsync(request: SaveOrganizationDTO) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/admin/organizations`,
      {
        request,
      }
    );
  }

  static saveAvatarPicAsync(request: OrganizationPicDTO) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/organizationpicture/${request.organizationId}/mainpic`,
      request
    );
  }
}
