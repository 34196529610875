import { createSlice } from '@reduxjs/toolkit';
import {
  initialize,
  loadTimeSpentItems,
  loadRevenueItems,
  loadResourceItems,
  loadTimesheetItems,
  loadTimeOffSummaries,
} from './dashboard-actions';
import { ContextActions } from '..';

import { forceRefreshMethods, forceRefreshState } from '../pagination';
import {
  RevenueItem,
  TimeSpentItem,
  ResourceItem,
  DashboardTimesheet,
  DashboardTimeOff,
  DashboardPayment,
  DashboardDocument,
  DashboardTimeOffSummary,
} from '../../shared/services/models/DashboardModel';
import {
  dateToString,
  firstOfMonth,
  lastOfMonth,
  today,
  yearsAgo,
} from '../../shared/DateUtil';

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    ...forceRefreshState(),

    timeOffSummaries: [] as Array<DashboardTimeOffSummary>,
    timesheetItems: [] as Array<DashboardTimesheet>,
    timeSpentItems: [] as Array<TimeSpentItem>,
    revenueItems: [] as Array<RevenueItem>,
    resourceItems: [] as Array<ResourceItem>,

    timeOffs: [] as Array<DashboardTimeOff>,
    payments: [] as Array<DashboardPayment>,
    documents: [] as Array<DashboardDocument>,

    timesheetFilter: null,
    timeSpentFilter: null,
    revenueFilter: null,
    resourceFilter: null,
    timeOffSummariesFilter: null,
    startDate: dateToString(yearsAgo(1, today())),
    endDate: dateToString(today()),
    tsStartDate: dateToString(firstOfMonth()),
    tsEndDate: dateToString(lastOfMonth()),
  },
  reducers: {
    ...forceRefreshMethods(),
    reset(state) {
      Object.assign(state, dashboardSlice.getInitialState());
    },
    setTimeSpentItems(state, action) {
      state.timeSpentItems = action.payload;
    },
    setRevenueItems(state, action) {
      state.revenueItems = action.payload;
    },
    setResources(state, action) {
      state.resourceItems = action.payload;
    },
    setTimesheetItems(state, action) {
      state.timesheetItems = action.payload;
    },

    setTimeOffs(state, action) {
      state.timeOffs = action.payload;
    },
    setTimeOffSummaries(state, action) {
      state.timeOffSummaries = action.payload;
    },
    setPayments(state, action) {
      state.payments = action.payload;
    },
    setDocuments(state, action) {
      state.documents = action.payload;
    },

    setDateRange(state, action) {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    setTsDateRange(state, action) {
      state.tsStartDate = action.payload.startDate;
      state.tsEndDate = action.payload.endDate;
    },
    setTimeSpentFilter(state, action) {
      state.timeSpentFilter = action.payload;
    },
    setRevenueFilter(state, action) {
      state.revenueFilter = action.payload;
    },
    setResourceFilter(state, action) {
      state.resourceFilter = action.payload;
    },
    setTimesheetFilter(state, action) {
      state.timesheetFilter = action.payload;
    },
    setTimeOffSummariesFilter(state, action) {
      state.timeOffSummariesFilter = action.payload;
    },
  },
});

export const dashboardActions = {
  ...dashboardSlice.actions,
  initialize,
  loadTimeSpentItems,
  loadRevenueItems,
  loadResourceItems,
  loadTimesheetItems,
  loadTimeOffSummaries,
};

export type DashboardContextType = ReturnType<
  typeof dashboardSlice.getInitialState
> &
  Partial<ContextActions<typeof dashboardActions>>;

export const DashboardContextValue = dashboardSlice.getInitialState();
