import { createSlice } from '@reduxjs/toolkit';

import {
  ContractDTO,
  ContractLineItemDTO,
  ContractTaskItemDTO,
} from '../../shared/services/models/ContractModel';

import {
  loadContract,
  loadContractItems,
  loadContractTasks,
  terminateContract,
  createContractItem,
  terminateContractItem,
  saveContractTask,
  updateContractTitle,
  updateContactEmail,
  updateContactName,
} from './contract-actions';
import { ContextActions } from '..';
import { UserInfoDTO } from '../../shared/services/models/UserModel';

export const contractSlice = createSlice({
  name: 'contract',
  initialState: {
    filter: 0,
    contractId: 0,
    showCreateContractItem: false,
    showCreateContractTask: false,
    contract: null as ContractDTO | null,
    contractLines: [] as Array<ContractLineItemDTO>,
    contractTasks: [] as Array<ContractTaskItemDTO>,
    selectedContractTask: null as ContractTaskItemDTO | null,
  },
  reducers: {
    setContract(state, action) {
      state.contract = action.payload;
      state.contractId = state.contract?.contractId ?? 0;
    },
    setContractId(state, action) {
      state.contractId = action.payload;
    },
    setSelectedContractTask(state, action) {
      state.selectedContractTask = action.payload;
    },
    setContractItems(state, action) {
      state.contractLines = action.payload;
    },
    setShowCreateContractItem(state, action) {
      state.showCreateContractItem = action.payload;
    },
    setContractTasks(state, action) {
      state.contractTasks = action.payload;
    },
    setShowCreateContractTask(state, action) {
      state.showCreateContractTask = action.payload;
    },
  },
});

export const contractActions = {
  ...contractSlice.actions,
  loadContract,
  loadContractItems,
  loadContractTasks,
  terminateContract,
  terminateContractItem,
  createContractItem,
  saveContractTask,
  updateContractTitle,
  updateContactEmail,
  updateContactName,
};

const extra = {
  users: [] as Array<UserInfoDTO>,
};

export type ContractContextType = ReturnType<
  typeof contractSlice.getInitialState
> &
  typeof extra &
  Partial<ContextActions<typeof contractActions>>;

export const ContractContextValue = {
  ...contractSlice.getInitialState(),
  users: [],
};
