import { approvalsActions, uiActions } from '..';
import { ApprovalsServices } from '../../shared/services/ApprovalsServices';
import { UserTimesheetToApproveDTO } from '../../shared/services/models/ApprovalModel';

import { DISPATCH, executeAction, GET_STATE } from '../actions';

export const loadApprovals = () => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const result = await ApprovalsServices.getPendingApprovalsAsync();
      dispatch(approvalsActions.setItems(result.data || []));
    });
  };
};

export const approveTimesheets = (item: UserTimesheetToApproveDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await ApprovalsServices.approveTimesheetsAsync(item.userTaskIds);
      dispatch(approvalsActions.removeItem(item));
      dispatch(
        uiActions.setMessage({
          messageType: 'success',
          message: 'User Tasks was successfully approved',
        })
      );
    });
  };
};

export const viewTimesheets = (item: UserTimesheetToApproveDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const result = await ApprovalsServices.getPendingApprovalDetailsAsync(
        item.userTaskIds
      );
      dispatch(approvalsActions.setDetails(result.data || []));
    });
  };
};
