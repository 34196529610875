import { Navigate, useLocation } from "react-router-dom";
import { useUser } from "../../hooks/useUser";

export function RequireAuth({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const user = useUser();

  if (!user) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return children;
}

export function RequireNoAuth({ children }: { children: JSX.Element }) {
  const user = useUser();
  const location = useLocation();
  if (user) {
    return <Navigate to='/' state={{ from: location }} replace />;
  }

  return children;
}
