import { useMemo } from 'react';
import { authToken } from '../shared/services/ApiServices';
import { UserInfo } from '../shared/services/models/AuthModel';
import { useLocalStorage } from '.';

function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

export function useUser() {
  const [userToken] = useLocalStorage<{ accessToken: string }>(authToken, {
    accessToken: '',
  });
  const user = useMemo(
    () =>
      userToken && userToken.accessToken
        ? (parseJwt(userToken.accessToken) as UserInfo)
        : null,
    [userToken]
  );
  return user;
}
