import { createSlice } from '@reduxjs/toolkit';
import { UserInfo } from '../../shared/services/models/AuthModel';
import {
  login,
  loginGoogle,
  login2Fac,
  logout,
  initialize,
  switchOrganization,
  changePassword,
  forgotPassword,
  resetPassword,
} from './auth-actions';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    userName: null as string | null,
    token2Fac: null as string | null,
    user: null as UserInfo | null,
  },
  reducers: {
    setLoginUser(state, action) {
      state.user = action.payload;
    },
    setTwoFacToken(state, action) {
      const { userName, token2Fac } = action.payload;
      state.userName = userName;
      state.token2Fac = token2Fac;
    },
    beginLogin(state) {
      state.loading = true;
    },
    endLogin(state) {
      state.loading = false;
    },
  },
});

export const authActions = {
  ...authSlice.actions,
  login,
  loginGoogle,
  login2Fac,
  logout,
  initialize,
  switchOrganization,
  changePassword,
  forgotPassword,
  resetPassword,
};
