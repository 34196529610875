import { createSlice } from '@reduxjs/toolkit';

import { loadSummary } from './bills-summary-actions';
import { dateToString, today } from '../../shared/DateUtil';
import { ContextActions } from '..';
import { BillSummaryDTO } from '../../shared/services/models/BillsModel';

export const billsSummarySlice = createSlice({
  name: 'billssummary',
  initialState: {
    items: [] as BillSummaryDTO[],
    startDate: dateToString(new Date(today().getFullYear(), 0, 1)),
    endDate: dateToString(new Date(today().getFullYear(), 11, 31)),
  },
  reducers: {
    reset(state) {
      Object.assign(state, billsSummarySlice.getInitialState());
    },
    setDateRange(state, action) {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    setItems(state, action) {
      state.items = action.payload;
    },
  },
});

export const billsSummaryActions = {
  ...billsSummarySlice.actions,
  loadSummary,
};

export type BillsSummaryContextType = ReturnType<
  typeof billsSummarySlice.getInitialState
> &
  Partial<ContextActions<typeof billsSummaryActions>>;

export const BillsSummaryContextValue = billsSummarySlice.getInitialState();
