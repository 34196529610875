import { createSlice } from '@reduxjs/toolkit';
import { ContextActions } from '..';
import {
  UserTimesheetToApproveDTO,
  UserTaskToApproveDTO,
} from '../../shared/services/models/ApprovalModel';
import {
  loadApprovals,
  approveTimesheets,
  viewTimesheets,
} from './approvals-actions';

export const approvalsSlice = createSlice({
  name: 'approvals',
  initialState: {
    items: [] as Array<UserTimesheetToApproveDTO>,
    details: [] as Array<UserTaskToApproveDTO>,
  },
  reducers: {
    reset(state) {
      Object.assign(state, approvalsSlice.getInitialState());
    },
    setDetails(state, action) {
      state.details = action.payload;
    },
    setItems(state, action) {
      state.items = action.payload;
    },
    removeItem(state, action) {
      const { clientId, userId } = action.payload;
      state.items = state.items.filter(
        (x) => !(x.clientId === clientId && x.userId === userId)
      );
    },
    hideDetails(state) {
      state.details = [];
    },
  },
});

export const approvalsActions = {
  ...approvalsSlice.actions,
  loadApprovals,
  approveTimesheets,
  viewTimesheets,
};

export type ApprovalsContextType = ReturnType<
  typeof approvalsSlice.getInitialState
> &
  Partial<ContextActions<typeof approvalsActions>>;

export const ApprovalsContextValue = approvalsSlice.getInitialState();
