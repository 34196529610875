import { ApiServices } from './ApiServices';
import {
  UserTaskToApproveDTO,
  UserTimesheetToApproveDTO,
} from './models/ApprovalModel';

export class ApprovalsServices {
  static getPendingApprovalsAsync() {
    return ApiServices.get<Array<UserTimesheetToApproveDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/timesheetsapproval`
    );
  }

  static getPendingApprovalDetailsAsync(userTaskIds: Array<number>) {
    return ApiServices.post<Array<UserTaskToApproveDTO>>(
      `${process.env.REACT_APP_ACC_API}/api/timesheetsapproval/details`,
      userTaskIds
    );
  }

  static approveTimesheetsAsync(model: Array<number>) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/timesheetsapproval/approve`,
      model
    );
  }
}
