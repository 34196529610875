import { SaveUserDTO } from '../../shared/services/models/UserModel';
import { UserServices } from '../../shared/services/UserServices';
import { DISPATCH, executeAction, GET_STATE } from '../actions';
import { uiActions } from '..';
import { userActions } from './user-slice';
import { usersActions } from './users-slice';
import { DashboardFilterDTO } from '../../shared/services/models/DashboardModel';

export const initializeRoles = () => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        user: { roles },
      } = getState();
      if (!roles || roles.length === 0) {
        const result = await UserServices.getRolesAsync();
        dispatch(userActions.setRoles(result.data || []));
      }
    });
  };
};

export const loadUser = (userId: number, canViewDocs: boolean) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      if (userId > 0) {
        const result = await UserServices.getUsersByIdAsync(userId);
        dispatch(userActions.setUser(result.data));
        if (canViewDocs) {
          const docResult = await UserServices.getDocumentAsync(userId);
          dispatch(userActions.setDocuments(docResult.data));
        }
      } else {
        dispatch(userActions.setUser({ userId: 0, roles: [] }));
      }
    });
  };
};

export const searchTimesheets = (
  userId: number,
  filter: DashboardFilterDTO
) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      if (userId > 0) {
        const docResult = await UserServices.getUserTimesheetsAsync(
          userId,
          filter
        );
        dispatch(userActions.setTimesheets(docResult.data));
      } else {
        dispatch(userActions.setTimesheets([]));
      }
    });
  };
};

export const saveUser = (user: SaveUserDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      if (user.userId) {
        await UserServices.updateUserAsync(user.userId, user);
      } else {
        await UserServices.createUserAsync(user);
      }
      dispatch(usersActions.doForceRefresh());

      dispatch(
        uiActions.setMessage({
          messageType: 'success',
          message: 'User save was successful',
        })
      );
    });
  };
};

export const terminateUser = (userId: number, termDate: string) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await UserServices.terminateUserAsync(userId, { endDate: termDate });
      dispatch(usersActions.doForceRefresh());
      dispatch(
        uiActions.setMessage({
          messageType: 'success',
          message: 'User termination was successful',
        })
      );
    });
  };
};

export const assignUserManager = (userId: number, managerUserId: number) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await UserServices.assignUserManagerAsync(userId, managerUserId);
      dispatch(usersActions.doForceRefresh());
      dispatch(
        uiActions.setMessage({
          messageType: 'success',
          message: 'User manager was successfully assigned',
        })
      );
    });
  };
};

export const downloadFile = (userId: number, docId: string) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const path = await UserServices.getDocumentUrlAsync(userId, docId);
      dispatch(userActions.setDocUrl(path.data));
    });
  };
};

export const approveDoc = (userId: number, docId: string) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await UserServices.approveDocAsync(userId, docId);
    });
    dispatch(userActions.loadUser(userId, true));
    dispatch(
      uiActions.setMessage({
        messageType: 'success',
        message: 'User document was successfully approved',
      })
    );
  };
};

export const rejectDoc = (userId: number, docId: string) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await UserServices.rejectDocAsync(userId, docId);
      dispatch(userActions.loadUser(userId, true));
      dispatch(
        uiActions.setMessage({
          messageType: 'success',
          message: 'User document was successfully rejected',
        })
      );
    });
  };
};
