import { Box, styled } from '@mui/material';
import { useRef, useState } from 'react';

const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  borderWidth: 0,
  borderRadius: theme.spacing(1),
  outline: '2px solid transparent',
  outlineColor: theme.palette.secondary.dark,

  outlineOffset: '2px',
  '&:focus': {
    outlineColor: theme.palette.primary.dark,

    outlineOffset: '0px',
  },
}));

export function OtpInput({
  numberOfDigits,
  onComplete,
}: {
  numberOfDigits: number;
  onComplete: (num: string) => void;
}) {
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(''));

  const otpBoxRef = useRef<any[]>([]);

  const handleChange = (value: string, index: number) => {
    const newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxRef.current[index + 1].focus();
    }

    onComplete(newArr.join(''));
  };

  const handleBackspaceAndEnter = (e: any, index: number) => {
    if (e.key === 'Backspace' && !e.target.value && index > 0) {
      otpBoxRef.current[index - 1].focus();
    }
    if (e.key === 'Enter' && e.target.value && index < numberOfDigits - 1) {
      otpBoxRef.current[index + 1].focus();
    }
  };

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      gap={2}
      justifyContent={'center'}
    >
      {otp.map((digit, index) => (
        <Box width={42} height={42} key={index}>
          <StyledInput
            type={'text'}
            value={digit}
            maxLength={1}
            onChange={(e) => handleChange(e.target.value, index)}
            onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
            ref={(reference) => (otpBoxRef.current[index] = reference)}
          />
        </Box>
      ))}
    </Box>
  );
}
