import { DISPATCH, executeAction, GET_STATE } from '../actions';

import { dashboardActions } from './dashboard-slice';
import { DashboardServices } from '../../shared/services/DashboardServices';
import { DashboardFilterDTO } from '../../shared/services/models/DashboardModel';
import { shallowEqual } from 'react-redux';

export const initialize = () => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const resultTimeoffs = await DashboardServices.getTimeOffsAsync();
      dispatch(dashboardActions.setTimeOffs(resultTimeoffs.data || []));

      const resultPayments = await DashboardServices.getPaymentsAsync();
      dispatch(dashboardActions.setPayments(resultPayments.data || []));

      const resultDocuments = await DashboardServices.getDocumentsAsync();
      dispatch(dashboardActions.setDocuments(resultDocuments.data || []));
    });
  };
};

export const loadTimeSpentItems = (filter: DashboardFilterDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        dashboard: { timeSpentItems, timeSpentFilter },
      } = getState();
      if (
        !timeSpentItems ||
        timeSpentItems.length === 0 ||
        !shallowEqual(timeSpentFilter, filter)
      ) {
        dispatch(dashboardActions.setTimeSpentFilter(filter));

        const result = await DashboardServices.getSpentItemsAsync(filter);
        dispatch(dashboardActions.setTimeSpentItems(result.data || []));
      }
    });
  };
};

export const loadRevenueItems = (filter: DashboardFilterDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        dashboard: { revenueItems, revenueFilter },
      } = getState();
      if (
        !revenueItems ||
        revenueItems.length === 0 ||
        !shallowEqual(revenueFilter, filter)
      ) {
        dispatch(dashboardActions.setRevenueFilter(filter));

        const result = await DashboardServices.getRevenueItemsAsync(filter);
        dispatch(dashboardActions.setRevenueItems(result.data || []));
      }
    });
  };
};

export const loadResourceItems = (filter: DashboardFilterDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        dashboard: { resourceItems, resourceFilter },
      } = getState();
      if (
        !resourceItems ||
        resourceItems.length === 0 ||
        !shallowEqual(resourceFilter, filter)
      ) {
        dispatch(dashboardActions.setResourceFilter(filter));

        const result = await DashboardServices.getResourcesAsync(filter);
        dispatch(dashboardActions.setResources(result.data || []));
      }
    });
  };
};

export const loadTimesheetItems = (filter: DashboardFilterDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        dashboard: { timesheetItems, timesheetFilter },
      } = getState();
      if (
        !timesheetItems ||
        timesheetItems.length === 0 ||
        !shallowEqual(timesheetFilter, filter)
      ) {
        dispatch(dashboardActions.setTimesheetFilter(filter));

        const result = await DashboardServices.getTimesheetItemsAsync(filter);
        dispatch(dashboardActions.setTimesheetItems(result.data || []));
      }
    });
  };
};

export const loadTimeOffSummaries = (filter: DashboardFilterDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        dashboard: { timeOffSummaries, timeOffSummariesFilter },
      } = getState();
      if (
        !timeOffSummaries ||
        timeOffSummaries.length === 0 ||
        !shallowEqual(timeOffSummariesFilter, filter)
      ) {
        dispatch(dashboardActions.setTimeOffSummariesFilter(filter));

        const result = await DashboardServices.getTimeOffSummariesAsync(filter);
        dispatch(dashboardActions.setTimeOffSummaries(result.data || []));
      }
    });
  };
};
