import { createSlice } from '@reduxjs/toolkit';
import { VariantType } from 'notistack';

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    loading: false,
    messageType: 'default' as VariantType,
    message: '',
  },
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setMessage(state, action) {
      const { messageType, message } = action.payload;
      state.messageType = messageType;
      state.message = message;
    },
    clearMessage(state) {
      state.messageType = 'default';
      state.message = '';
    },
  },
});
export const uiActions = uiSlice.actions;
