import { organizationsActions, uiActions } from '..';
import { DISPATCH, executeAction, GET_STATE } from '../actions';
import { AdminServices } from '../../shared/services/AdminServices';
import {
  GetOrganizationsByFilterDTO,
  OrganizationDTO,
  OrganizationPicDTO,
} from '../../shared/services/models/OrganizationModel';
import { shallowEqual } from 'react-redux';

export const search = (filter: GetOrganizationsByFilterDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      const {
        organizations: { items, lastOrganizationFilter },
      } = getState();
      if (
        !items ||
        items.length === 0 ||
        !shallowEqual(filter, lastOrganizationFilter)
      ) {
        dispatch(organizationsActions.setLastOrganizationFilter(filter));
        const result = await AdminServices.getOrganizationsAsync(filter);
        dispatch(organizationsActions.setItems(result.data.items));
        dispatch(organizationsActions.setTotalCount(result.data.totalCount));
      }
    });
  };
};

export const saveOrganization = (organization: OrganizationDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      if (organization.organizationId) {
        await AdminServices.updateOrganizationAsync(
          organization.organizationId,
          { ...organization }
        );
      } else {
        await AdminServices.createOrganizationAsync({
          ...organization,
        });
      }
      dispatch(organizationsActions.doForceRefresh());
      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Organization was successfully saved',
        })
      );
    });
  };
};

export const saveAvatarPic = (profilePic: OrganizationPicDTO) => {
  return async (dispatch: DISPATCH, getState: GET_STATE) => {
    await executeAction(dispatch, async () => {
      await AdminServices.saveAvatarPicAsync(profilePic);
      dispatch(organizationsActions.setShowAvatarPicEdit(0));
      dispatch(organizationsActions.doForceRefresh());

      dispatch(
        uiActions.setMessage({
          messageType: 'info',
          message: 'Organization was successfully saved',
        })
      );
    });
  };
};
