import {
  addMonths,
  addYears,
  endOfMonth,
  format,
  formatDistance,
  isDate,
  isSameMonth,
  lastDayOfWeek,
  parse,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfWeek,
  differenceInDays,
} from 'date-fns';

export function today() {
  return startOfDay(new Date());
}

export function firstOfMonth(dt?: Date) {
  return startOfMonth(dt ?? new Date());
}

export function lastOfMonth(dt?: Date) {
  return endOfMonth(dt ?? new Date());
}

export function areOnSameMonth(dt1: Date, dt2: Date) {
  return isSameMonth(dt1, dt2);
}

export function calendarMonth(dt: Date) {
  return {
    startDate: startOfWeek(firstOfMonth(dt)),
    endDate: lastDayOfWeek(lastOfMonth(dt)),
  };
}

export function yearsAgo(years: number, date: Date): Date {
  return addYears(date, -years);
}

export function dayDiff(s: Date, e: Date) {
  return differenceInDays(s, e);
}

export function addDays(days: number, date: Date): Date {
  const futureDate = date;
  futureDate.setDate(futureDate.getDate() + days);
  return futureDate;
}

export function toDate(strDate: string) {
  try {
    return parse(strDate.substring(0, 10), 'yyyy-MM-dd', new Date());
  } catch {
    return null;
  }
}

export function toDateISO(strDate: string) {
  try {
    return parseISO(strDate);
  } catch {
    return null;
  }
}

export function toFormatedDateRange(stDate: string, edDate: string) {
  const sDate = toDate(stDate);
  const eDate = toDate(edDate);
  if (sDate?.getFullYear() === eDate?.getFullYear()) {
    if (sDate?.getMonth() === eDate?.getMonth()) {
      if (sDate?.getDay() === eDate?.getDay()) {
        return `${format(eDate!, 'do MMM yyyy')}`;
      }
      return `${format(sDate!, 'do')} - ${format(eDate!, 'do MMM yyyy')}`;
    } else {
      return `${format(sDate!, 'do MMM')} - ${format(eDate!, 'do MMM yyyy')}`;
    }
  } else {
    return `${format(sDate!, 'do MMM yyyy')} - ${format(
      eDate!,
      'do MMM yyyy'
    )}`;
  }
}

export function toFormattedDate(strDate: string, toFormat?: string) {
  try {
    const dt = toDate(strDate);
    return dt ? format(dt, toFormat ?? 'dd MMM yyyy') : '';
  } catch {
    return null;
  }
}

export function toFormattedDateEx(strDate: string, toFormat?: string) {
  try {
    const dt = new Date(strDate);
    return dt ? format(dt, toFormat ?? 'dd MMM yyyy') : '';
  } catch {
    return null;
  }
}

export function getDateString(date: Date) {
  let month = '' + (date.getMonth() + 1);
  let day = '' + date.getDate();
  const year = date.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [month, year, day].join('-');
}

export const dateToString = (date: Date) =>
  date ? format(date, 'yyyy-MM-dd') : undefined;

export const isValidDate = (date?: Date) => isDate(date);

export const getMonthDateRange = (month: number) => {
  const dt = new Date(new Date().getFullYear(), month, 1);
  const ed = addDays(-1, addMonths(dt, 1));
  return { startDate: dt, endDate: ed };
};

export const getSinceMonthDateRange = (month: number) => {
  const ed = new Date();
  const dt = new Date(ed.getFullYear(), ed.getMonth() + month, 1);

  return { startDate: dt, endDate: ed };
};

export const getYearDateRange = (yearOffset: number) => {
  const dt = new Date(new Date().getFullYear() + yearOffset, 0, 1);
  const ed = addDays(-1, addYears(dt, 1));
  return { startDate: dt, endDate: ed };
};

export function isBetween(date: string, beginDate: string, endDate: string) {
  const dtBegin = toDate(beginDate)!;
  const dtEnd = toDate(endDate);
  const theDate = toDate(date)!;
  return dtBegin <= theDate && (!dtEnd || theDate! <= dtEnd);
}

export function momentsAgo(dt?: Date | null) {
  return dt ? formatDistance(dt, new Date(), { addSuffix: true }) : '';
}
