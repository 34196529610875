import { ApiServices } from './ApiServices';
import { SaveUserPicDTO } from './models/UserModel';

export class ProfilePictureServices {
  static saveMyProfilePicAsync(request: SaveUserPicDTO) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/profilepicture/mainpic`,
      request
    );
  }

  static saveMyAvatarPicAsync(request: SaveUserPicDTO) {
    return ApiServices.post<number>(
      `${process.env.REACT_APP_ACC_API}/api/profilepicture/avatarpic`,
      request
    );
  }
}
