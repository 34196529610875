import { createSlice } from '@reduxjs/toolkit';
import { ContextActions } from '..';
import {
  ClientDTO,
  ClientStatusEnum,
} from '../../shared/services/models/ClientModel';
import {
  initializeClients,
  toggleStatus,
  createClient,
  updateClient,
} from './clients-actions';

export const clientsSlice = createSlice({
  name: 'clients',
  initialState: {
    selectedClient: null as ClientDTO | null,
    lastFilter: null,
    allItems: [] as Array<ClientDTO>,
    items: [] as Array<ClientDTO>,
    filter: ClientStatusEnum.All,
  },
  reducers: {
    reset(state) {
      Object.assign(state, clientsSlice.getInitialState());
    },

    setSelectedClient(state, action) {
      state.selectedClient = action.payload;
    },
    setItems(state, action) {
      state.allItems = action.payload;
      state.items = action.payload;
      state.filter = ClientStatusEnum.All;
    },
    setFilter(state, action) {
      state.filter = action.payload;
      state.items = state.allItems.filter(
        (x) =>
          state.filter === ClientStatusEnum.All || x.statusId === state.filter
      );
    },
    updateStatus(state, action) {
      const client = state.allItems.find(
        (x) => x.clientId === action.payload.clientId
      );
      if (client) {
        client.statusId = action.payload.statusId;
      }
    },
    addClient(state, action) {
      state.allItems.push(action.payload);
    },
    saveClient(state, action) {
      const client = action.payload;
      state.allItems = state.allItems.map((c) =>
        c.clientId === client.clientId ? client : c
      );
    },
    setLastFilter(state, action) {
      state.lastFilter = action.payload;
    },
  },
});

export const clientsActions = {
  ...clientsSlice.actions,
  initializeClients,
  toggleStatus,
  createClient,
  updateClient,
};

export type ClientsContextType = ReturnType<
  typeof clientsSlice.getInitialState
> &
  Partial<ContextActions<typeof clientsActions>>;

export const ClientsContextValue = clientsSlice.getInitialState();
