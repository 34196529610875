import { createSlice } from '@reduxjs/toolkit';
import {
  InvoicePickItemDTO,
  InvoiceSummaryDTO,
} from '../../shared/services/models/InvoiceModel';

import {
  searchInvoices,
  loadInvoiceSummary,
  completeInvoice,
  loadClients,
} from './invoices-actions';
import { pageInitialState, pageReducerMethods } from '../pagination';
import { dateToString, today } from '../../shared/DateUtil';
import { ContextActions } from '..';
import { ClientDTO } from '../../shared/services/models/ClientModel';

export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState: {
    ...pageInitialState<InvoicePickItemDTO>(),
    lastFilter: null,

    sortColumn: 'invoiceId',
    sortDirection: 1,

    clients: [] as Array<ClientDTO>,
    clientId: 0,
    startDate: dateToString(new Date(today().getFullYear(), 0, 1)),
    endDate: dateToString(new Date(today().getFullYear(), 11, 31)),
    statusId: 0,

    summary: {
      totalCount: 0,
      totalAmount: 0,

      paidCount: 0,
      paidAmount: 0,

      unPaidCount: 0,
      unPaidAmount: 0,

      overdueCount: 0,
      overdueAmount: 0,
    } as InvoiceSummaryDTO,
  },
  reducers: {
    ...pageReducerMethods,
    reset(state) {
      Object.assign(state, invoicesSlice.getInitialState());
    },
    setInvoiceStatus(state, action) {
      const { invoiceId, statusId } = action.payload;
      state.items
        .filter((x) => x.invoiceId === invoiceId)
        .forEach((x) => (x.statusId = statusId));
    },
    setClientId(state, action) {
      state.clientId = action.payload;
    },
    setDateRange(state, action) {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    setSummary(state, action) {
      state.summary = action.payload;
    },
    setFilterStatus(state, action) {
      state.statusId = action.payload;
    },
    setClients(state, action) {
      state.clients = action.payload;
    },
  },
});

export const invoicesActions = {
  ...invoicesSlice.actions,
  searchInvoices,
  loadInvoiceSummary,
  completeInvoice,
  loadClients,
};

export type InvoicesContextType = ReturnType<
  typeof invoicesSlice.getInitialState
> &
  Partial<ContextActions<typeof invoicesActions>>;

export const InvoicesContextValue = invoicesSlice.getInitialState();
